<template>
  <div class="cusSerCenter">
    <breadcrumb :link-list="breadcrumbList"></breadcrumb>
    <div class="header">
      <div class="headTitle">
        <img class="headIcon" src="@/assets/image/AccountDeal/gameSelectHead/cusSerIcon.png">
        <div class="headText">{{step===1?'客服中心':'客服列表'}}</div>
        <img class="headTip" v-if="step===1" src="@/assets/image/AccountDeal/gameSelectHead/cusSerTip.png">
      </div>
      <div class="headBack" v-if="step!==1" @click="backBtn">返回</div>
      <router-link :to="{name:'Home'}" class="headBack" v-else >返回首页</router-link>
    </div>
    <!--  游戏列表 gameList -->
    <div class="gameList" v-if="step===1">
      <gameList :gameClick="gameClick" other></gameList>
    </div>
    <!--  客服列表 cusSerList -->
    <div class="cusSerList" v-else-if="step===2">
      <div class="head">
        <img :src="selectGame.game_image" alt="">
        {{ selectGame.game_name }}&ensp;-&ensp;客服列表
      </div>
      <!-- <div class="cus_main"> -->
        <div class="list" v-if="selectGame.game_id==10" style="padding:0;">
          <a :class="item.isonline === 1 ?'':'afterWork'" class="cusSer" v-for="(item,index) in cursList" :key="index" @click="customClicks(item)" target="_blank">
            <div class="info">
              <img src="@/assets/image/Home/cusSerCenterIcon.png" alt="">
              <div class="name">{{ item.nickname }}</div>
            </div>
            <a class="btn" v-if="item.isonline === 1" @click.stop="customClicks(item)" target="_blank">咨询客服</a>
            <a class="btn" v-else target="_blank">客服不在线</a>
          </a>
          <!-- <a :class="item.is_work === 0 ?'afterWork':''" class="cusSer" v-for="(item,index) in selectGame.buy_list" :key="index" @click="customClick(item)" target="_blank">
            <div class="info">
              <img src="@/assets/image/Home/cusSerCenterIcon.png" alt="">
              <div class="name">{{ item.work_name }}</div>
            </div>
            <a class="btn" @click.stop="customClick(item)" v-if="item.is_work === 1" target="_blank">咨询客服</a>
            <a class="btn"  v-else target="_blank">客服不在线</a>
          </a> -->
        </div>
        <div class="list" v-else>
          <a :class="item.is_work === 0 ?'afterWork':''" class="cusSer" v-for="(item,index) in selectGame.buy_list" :key="index" @click="customClick(item)" target="_blank">
            <div class="info">
              <img src="@/assets/image/Home/cusSerCenterIcon.png" alt="">
              <div class="name">{{ item.work_name }}</div>
            </div>
            <a class="btn" @click.stop="customClick(item)" v-if="item.is_work === 1" target="_blank">咨询客服</a>
            <a class="btn"  v-else target="_blank">客服不在线</a>
          </a>
        </div>
      <!-- </div> -->
    </div>
  </div>
</template>
<script>

import {apiRecyclingList, apiGameList} from "@/request/API";
import breadcrumb from "@/components/breadcrumb";
import gameList from "@/components/gameList/index"
import chatApi from '@/request/customer/customerRoom'
import {Decrypt} from '@/request/customer/util.js'
export default {
  name: 'cusSerCenter',
  props: {},
  components: {
    breadcrumb,
    gameList
  },
  data() {
    return {
      // gameList 、 merchantList 、 merchantInfo
      step: 1,
      // 面包屑
      breadcrumbList: [
        {link: 'CusSerCenter', name: '客服中心'}
      ],
      // 选择的游戏
      selectGame: '',
      cursList:[],
      game_id: ''
    }
  },
  created() {
    // this.cueGrouplist()
    this.game_id = this.$route.query?.game_id
    if(this.game_id){
      this.step = 2
      apiGameList({
        game_id:this.game_id
      }).then(res => {
        this.selectGame = res.data[0]
        this.getcustomercarelist()
      })
    }
  },
  methods: {
    /**
     * @description: 获取客服分组列表
     * @return {*}
     */
    cueGrouplist() {
      chatApi.grouplist({
        msg: chatApi.encryptMsg({
          format: 'json',
          type: 3
        })
      }).then(res => {
        // console.log('客服分组列表',JSON.parse(Decrypt(res.result)));
      }).catch(err => {
        console.log(err);
      })
    },
    /**
     * @description: 根据分组标识获取客服列表
     * @return {*}
     */
    getcustomercarelist() {
      chatApi.getcustomercarelistbycode({
        msg: chatApi.encryptMsg({
          format: 'json',
          type: 3,
          code: this.selectGame.game_alias
        })
      }).then(res => {
        let data = JSON.parse(Decrypt(res.result))
        this.cursList = data.customercarelist
        // console.log(data);
      }).catch(err => {
        console.log(err);
      })
    },
    customClicks(item) {
      if(item.isonline == 1){
        let routeData = this.$router.resolve({
          name: 'CustomerSer',
          query: {
            id: item.id,
            code: this.selectGame.game_alias
          }
        })
        window.open(routeData.href, '_blank')
      } else {
        this.$message({
          center:true,
          type:'error',
          message:"当前客服不在线"
        })
      }

    },
    /**
     * 点击游戏事件
     * @param game
     */
    gameClick(game) {
      this.selectGame = game
      document.body.scrollTop = document.documentElement.scrollTop = 157
      this.step++
    },
    /**
     * 点击客服事件
     * @param cusSer
     */
    cusSerClick(cusSer) {
      $accessLink(cusSer.work_id)
    },
    /**
     * 返回按钮
     */
    backBtn() {
      this.step--
    },
    customClick(item){
      if (item.is_work === 1) {
        this.$accessLink(item.work_id)
        window.open(`${item.work_id}?u_cust_id=${this.$store.state.userData.user_id}&u_cust_name=${this.$store.state.userData.nickname}`,'_blank')
      }else{
        this.$message({
          center:true,
          type:'error',
          message:"当前客服不在线"
        })
      }
    }

  },
  mounted() {
    if (this.$store.state.loginStatus) {
      sby_setuserid(this.$store.state.userData.telphone, this.$store.state.userData.nickname, this.$store.state.userData.avatar == '' ? 'https://m.pxb7.com/image/public/avatar.jpg' :this.$store.state.userData.avatar)
    } else {
      this.$message({
        type: "error",
        message: '尚未登录，请先登录。',
        center: true
      })
      this.$router.push({
        path: '/login',
        query: {
          Rurl: this.$route.path,
        },
      })
    }
  },
  watch: {
    step(val) {
      switch (val) {
        case 1:
          this.breadcrumbList = [
            {link: 'CusSerCenter', name: '客服中心'}
          ]
          break;
        case 2:
          this.breadcrumbList = [
            {name: '客服中心'},
            {name: '客服列表'},
          ]
          this.getcustomercarelist()
          break;
      }
    }
  },
  computed: {}
}
</script>

<style lang='less' scoped>
.cusSerCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1200px;

  .header {
    width: calc(1200px - 88px);
    height: 70px;
    background: linear-gradient(90deg, #163279 0%, rgba(0,8,77,0.8000) 100%);
    border-radius: 10px 10px 0 0;
    padding: 0 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .headTitle {
      display: flex;
      flex-direction: row;
      align-items: center;

      .headText {
        font-size: 24px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        text-align: CENTER;
        color: #ffffff;
        margin: 0 29px 0 8px;
      }

    }

    .headBack {
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      color: #ffffff;
      cursor: pointer;
    }
  }


  .cusSerList {
    width: calc(1200px - 50px);
    background: #ffffff;
    padding: 0 25px 100px;

    .head {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 40px 18px;
      font-size: 24px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #4a4a4a;
      border-bottom: 2px solid #f0f0f0;

      img {
        width: 80px;
        height: 80px;
        border-radius: 20px;
        margin: 0 25px 0 0;
      }
    }
    .cus_main{
      display: flex;
      justify-content: flex-start;
    }
    .list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0 6px;
      margin: 20px 0 0;

      .cusSer {
        width: 168px;
        height: 178px;
        background: #f9f9f9;
        border: 1px solid #f9f9f9;
        border-radius: 10px;
        margin: 20px 9px 0;
        cursor: pointer;
        .info{
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 14px;
          padding: 26px 0 21px;
          border-bottom: 1px solid #e0e3e5;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #000000;
          img{
            width: 30px;
            height: 30px;
            margin: 0 0 10px;
          }

        }
        .btn{
          display: block;
          width: 121px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background: #434ED6;
          border-radius: 30px;
          margin: 20px auto;
          color: #FFFFFF;
        }

      }
      .cusSer:hover{
        border: 1px solid #ff8c0c;
      }

      .afterWork{
        filter: grayscale(1);
        cursor: default;
      }
    }
  }

}
</style>
